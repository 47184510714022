<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_countPlanAdd" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete first-search-item">
              <!-- 取引先-->
              <div v-if="suppliersSelectedName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        id="get-supplier"
                        v-model="suppliersSelectedName"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelectedName }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    id="get-supplier"
                    v-model="suppliersSelectedName"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    readonly
                    persistent-hint
                  >
                  </v-text-field>
                </div>
              </div>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <!-- 棚卸方式 -->
              <v-text-field
                dense
                v-model="inventoryMethodSelectedName"
                :label="$t('label.lbl_invatoryMethod')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 棚卸日-->
              <v-text-field
                outlined
                dense
                v-model="inventoryDate"
                :label="$t('label.lbl_invatoryDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd, rules.dateCheck(inventoryDate, approvalFlg)]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="approvalFlg"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <!-- 棚卸日カレンダー-->
              <v-menu
                v-model="inventoryDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
                :disabled="approvalFlg"
              >
                <template v-if="!approvalFlg" v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="inventoryDateCal"
                  :allowed-dates="allowedDate"
                  @input="inventoryDateMenu = false"
                  v-if="!approvalFlg"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                :disabled="approvalFlg"
                class="day-ctrl-btn"
                @click="prevDate"
                text
                x-small
              >
                <!-- 前日-->
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                :disabled="approvalFlg"
                class="day-ctrl-btn"
                @click="nextDate"
                text
                x-small
              >
                <!-- 次日-->
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--棚卸計画No-->
            <div class="serch-textbox-arrivalNo">
              <!--新規登録の場合指示No編集可 -->
              <v-text-field
                outlined
                dense
                v-model="inventoryPlanNo"
                maxlength="30"
                :label="$t('label.lbl_inventoryPlanNo')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                :disabled="approvalFlg || !noDisplayFlg"
              />
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償/無償-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
                :disabled="approvalFlg"
              >
                <v-btn class="paid-btn" :disabled="approvalFlg" value="01" small>
                  {{ $t("btn.btn_paid") }}
                </v-btn>
                <v-btn class="paid-btn" :disabled="approvalFlg" value="02" small>
                  {{ $t("btn.btn_free") }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" class="api-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--一括削除 -->
                <v-btn
                  v-if="registrationUpdateFlag == '1'"
                  color="primary"
                  id="btn-search"
                  class="other-btn"
                  @click="deleteAll"
                  :disabled="approvalFlg"
                  >{{ $t("btn.btn_batchDelete") }}</v-btn
                >
                <!--登録ボタン-->
                <v-btn
                  color="primary"
                  :disabled="noPlanFlg || approvalFlg"
                  class="api-btn"
                  @click="planAdd"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="630px"
          :page.sync="page"
        >
          <template v-slot:[`item.locationGroup`]="{ item }"
            >{{ $t("label.lbl_locaGroup") }} {{ item.locationGroup }}
          </template>
          <!-- 担当者 -->
          <template v-slot:[`item.person`]="{ item }">
            <v-autocomplete
              dense
              outlined
              class="text-box"
              v-model="item.person"
              :items="userList"
              maxlength="10"
              :disabled="item.completeFlg == 1 || item.isResult == 1"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size">
                  {{ data.item.text }}
                </span>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-btn
              v-if="item.completeFlg != 1 && item.isResult != 1"
              small
              @click="deleteItem(item)"
              text
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <!-- ロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span v-if="stockCheckTargetFlag">
              {{ header.text }}<br />
              {{
                $t("label.lbl_dueDate") +
                "／" +
                $t("label.lbl_lotSubkey1") +
                "／" +
                $t("label.lbl_lotSubkey2")
              }}
            </span>
          </template>
          <!-- 理論在庫 -->
          <template v-slot:[`header.stock`]="{ header }">
            <div v-if="stockCheckTargetFlag">
              {{ $t("label.lbl_theoreticalStock") }}
              {{ header.stock }}
            </div>
          </template>
          <!-- 品番/品名 -->
          <template v-slot:[`header.productCnCd`]="{ header }">
            <div v-if="stockCheckTargetFlag">
              {{ $t("label.lbl_productCnCd") }}
              {{ header.productCnCd }}
            </div>
          </template>

          <!-- 品番・品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="stockCheckTargetFlag">
              <div v-if="item.productName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}
                    </div>
                    <div class="text-overflow">{{ item.incidental }}</div>
                    <div class="text-overflow">{{ item.productName }}</div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.incidental }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.incidental }}</div>
                <div>{{ item.productName }}</div>
              </div>
            </div>
          </template>
          <!--ロット -->
          <template v-slot:[`item.lot`]="{ item }">
            <span v-if="stockCheckTargetFlag" class="align-left listNo-style">
              <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 300px" v-bind="attrs" v-on="on">
                      {{ item.lot }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <span
                    >{{ item.lot }}<br />
                    {{ item.dueDate }}
                  </span>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lot }}<br />
                {{ item.dueDate }}
              </div>
            </span>
          </template>
        </v-data-table>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :sixPageFlag="infoDialog.sixPageFlag"
        :countInstSearchFlag="infoDialog.countInstSearchFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :countInstSearchPageParams="infoDialog.countInstSearchPageParams"
      />

      <!-- 承認済み確認メッセージダイアログモーダル-->
      <v-dialog v-model="approvalDialog.completeDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ approvalDialog.completeMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okcopy"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :cancelAction="cancelDelete"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "../../components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_CNT_002,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 前のページから受け取る
    person: "", //担当者
    warehouse: "", //倉庫
    location: "", //ロケーション
    coutPlanListParameta: "", //検索条件
    // 計画No採番のために必要なCd
    clientCd: "",
    officeCd: "",
    // 棚卸日
    inventoryDateCal: "",
    inventoryDate: "",
    inventoryDateMenu: false,
    // 取引先プルダウン
    suppliersSelectedName: "",
    suppliersSelected: "",
    supplierList: [],
    // 棚卸方式プルダウン
    inventoryMethodSelected: "",
    inventoryMethodList: [],
    inventoryMethodSelectedName: "",
    // 倉庫プルダウン
    warehouseSelected: "",
    warehouseList: [],
    // 棚卸計画No
    inventoryPlanNo: "",
    noDisplayFlg: true,
    // 有償無償
    isPaid: "02",
    // 削除用
    deleteList: [],
    beforePage: "",
    // 検索用日付
    searchDate: "",
    // メニュー
    openMenu: null,
    editedIndex: -1,
    // 登録ボタンの非活性化フラグ
    noPlanFlg: false,
    // 登録/更新フラグ
    registrationUpdateFlag: "",
    // 一括削除フラグ
    deleteAllFlg: false,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      sixPageFlag: false,
      countInstSearchFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
      // 画面遷移時のパラメータ
      countInstSearchPageParams: {},
    },
    // 登録ボタン押下時確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 確認メッセージダイアログ
    approvalDialog: {
      completeDialogMsg: false,
      completeMessage: "",
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 棚卸方式が荷動き品番の場合
    stockCheckTargetFlag: false,
    // 前画面からもらうlocationSidを格納する配列
    locationSidList: [],
    officeSidForApi: "",
    warehouseSidForApi: "",
    // 承認済みフラグ
    approvalFlg: false,
    // 担当者リスト
    userList: [],
    // 期待値
    dispOnOffFlg: "",
    headerItems: [
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locaGroup"),
        value: "locationGroup",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 理論在庫数
      // {
      //   text: "理論在庫",
      //   value: "stock",
      //   width: "5%",
      //   align: "left",
      //   sortable: false,
      // },

      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "person",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
    // 一覧
    inputList: [],
    // 入力チェック
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      /**
       * 日付の当日以降チェック
       * @param {*} value チェック対象日付（yyyy-MM-dd）
       */
      dateCheck(value, approvalFlg) {
        if (dateTimeHelper.isDateAfterTheDay(value) || approvalFlg) {
          // 当日以降の場合は処理なし
          return true;
        } else {
          // 当日より前日付の場合はエラー
          return i18n.tc("check.chk_DateAfterTheDay");
        }
      },
    },
  }),
  methods: {
    // 計画登録ボタン押下**登録しますかのアラート**
    planAdd() {
      if (this.$refs.form.validate()) {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
    },
    // 戻るボタン押下
    backPage() {
      if (this.approvalFlg) {
        this.updateOk();
      } else {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      }
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 元画面が一覧の場合
      if (this.beforePage == "CntInstList") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CNT_003,
          params: {
            // 遷移するときに渡された検索条件を返す
            returnParam: this.coutPlanListParameta, // 検索条件
            suppliersSelected: this.suppliersSelected, //取引先
            person: this.person, //担当者
            warehouse: this.warehouse, //倉庫
            location: this.location, //ロケーション
          },
        });
        // 元画面が登録（検索）の場合
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CNT_001,
          params: {
            // 遷移するときに渡された検索条件を返す
            suppliersSelected: this.suppliersSelected, //取引先
            method: this.inventoryMethodSelected, //棚卸方式
            warehouse: this.warehouseSelected, //倉庫
            dispOnOffFlg: this.dispOnOffFlg, // 期待値
          },
        });
      }
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 取引先取得**取引先Cdと営業所Cdが必要な為getParamater不使用**
    getCustomInfo() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  officeCd: row.clientCd, //取引先Cd
                });
              });

              // this.$route.paramsで受け取った取引先Sidと一致する取引先名をセット
              for (let i = 0; i < list.length; i++) {
                if (this.suppliersSelected == list[i].value) {
                  this.suppliersSelectedName = list[i].text;
                  this.supplierList = list[i];
                }
              }
              // 新規登録の場合
              if (this.beforePage == "CntInstSearch") {
                this.setPlanNo();
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    // 棚卸方式プルダウン
    getMstCode() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.INVENTORY_MET);

      Promise.all([methodList])
        .then((result) => {
          this.inventoryMethodList = result[0];

          // 前画面から受け取った棚卸方式をセット
          for (let i = 0; i < this.inventoryMethodList.length; i++) {
            if (this.inventoryMethodSelected == this.inventoryMethodList[i].value) {
              this.inventoryMethodSelectedName = this.inventoryMethodList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          // this.loadingCounter = 0;
        });
    },
    // 棚卸明細Get
    getPlanDetails() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.stockCheckNo = this.inventoryPlanNo;
      config.params.clientSid = this.suppliersSelected;
      config.params.stockCheckDiv = this.inventoryMethodSelected;
      config.params.date = this.$route.params.countImplementationDate;
      config.params.warehouseSid = this.$route.params.warehouseSid;
      config.params.deleteFlg = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.CNT_PLANDETAILS, config)
          .then((response) => {
            // console.debug("getPlanDetails() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.stockCheckPlanDtl.forEach((row) => {
                // 削除されいている明細は表示しないように制御するため
                // deleteFlgが0のもののみ取得する
                if (row.deleteFlg == "0") {
                  let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    stockCheckNo: row.stockCheckNo, //棚卸指示No
                    yyyymmddHhmmss: dateTimeHelper.convertUTC2JST(this.yyyymmddHhmmss), //日時
                    officeSid: row.officeSid, //営業所SID
                    clientSid: row.clientSid, //取引先SID
                    warehouseSid: row.warehouseSid, //倉庫SID
                    locationSid: row.locationSid, //ロケーションSID
                    locationGroup: row.locationGroup, //ロケーショングループ
                    location: row.locationNo, //ロケーションNo
                    stockCheckDiv: row.stockCheckName, //棚卸方式
                    freeCostDiv: row.freeCostDiv, //有償無償区分
                    userSid: row.userSid, //担当者SID
                    stockCheckSid: row.stockCheckSid, //棚卸SID:計画一覧からの修正の場合に使用
                    updateDatetime: row.updateDatetime, //更新日時:計画一覧からの修正の場合に使用
                    productCnCdShow: row.itemCd,
                    productCnCd: row.itemCd.substring(0, 50).trim(),
                    incidental: row.itemCd.slice(-50).trim(),
                    productName: row.itemName == null ? "" : row.itemName,
                    lotShow: row.lotNo, // ロット
                    lot: lotNoObj.lot, // ロット、S/N
                    dueDate: lotNoObj.dueDate, // 期限日、付属キー１、付属キー２
                    completeFlg: row.completeFlg,
                    approvalFlg: row.approvalFlg, // 承認フラグ
                    isResult: row.isResult, // 結果フラグ
                    autoNo: row.autoNo, // 連番
                  });
                }
              });
              this.inputList = list;
              // 削除・更新用にdeleteListにおいておく
              var deleteList = list;
              for (var i = 0; i < deleteList.length; i++) {
                deleteList[i].deleteFlg = "0";
                this.deleteList.push(deleteList[i]);
              }
              // プルダウンにセットするためユーザー情報を取得する
              this.getUsers();

              // すべて承認済みか判定
              this.approvalFlg = list.every((val) => {
                return val.approvalFlg == "1";
              });

              resolve(response);

              if (this.approvalFlg) {
                this.approvalDialog.completeMessage = messsageUtil.getMessage("P-CNT-006_004_C");
                this.approvalDialog.completeDialogMsg = true;
              }
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 完了フラグダイアログを閉じる
    okcopy() {
      this.approvalDialog.completeDialogMsg = false;
    },

    // ユーザー情報取得
    getUsers() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // ★必須パラメータ
      config.params.searchCategoryDiv = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getUsers() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                  userId: row.userId, //ユーザーID
                });
              });
              this.userList = list;
              // 取得したユーザーリストの中から、明細のユーザーSidと一致するものを探す
              for (var i = 0; i < this.inputList.length; i++) {
                for (var ii = 0; ii < this.userList.length; ii++) {
                  if (this.inputList[i].userSid == this.userList[ii].value) {
                    // inputListの担当者（person）に一致したユーザーSidを入れる
                    this.inputList[i].person = this.userList[ii].value;
                  }
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    // 一括削除ボタン押下
    deleteAll() {
      // 一括削除フラグをONにする
      this.deleteAllFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_001_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },
    cancelDelete() {
      this.deleteAllFlg = false;
    },
    postApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      //★必須パラメータ
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_002;

      var stockCheckPlan = [];
      if (this.beforePage == "CntInstList") {
        // 計画を一括削除削除する場合
        if (this.deleteAllFlg) {
          for (let dAll = 0; dAll < this.deleteList.length; dAll++) {
            if (this.inventoryMethodSelected == "03" || this.inventoryMethodSelected == "05") {
              stockCheckPlan.push({
                locationSid: this.deleteList[dAll].locationSid, //ロケーションSID
                locationGroup: this.deleteList[dAll].locationGroup, //ロケーショングループ
                userSid: this.deleteList[dAll].person, //担当者SID
                deleteFlg: "1", //削除フラグを1にセット
                stockCheckSid: this.deleteList[dAll].stockCheckSid, //棚卸SID
                updateDatetime: this.deleteList[dAll].updateDatetime, //更新日
                autoNo: this.deleteList[dAll].autoNo, // 連番
              });
            } else {
              stockCheckPlan.push({
                locationSid: this.deleteList[dAll].locationSid, //ロケーションSID
                locationGroup: this.deleteList[dAll].locationGroup, //ロケーショングループ
                userSid: this.deleteList[dAll].person, //担当者SID
                deleteFlg: "1", //削除フラグを1にセット
                stockCheckSid: this.deleteList[dAll].stockCheckSid, //棚卸SID
                updateDatetime: this.deleteList[dAll].updateDatetime, //更新日
              });
            }
          }
        } else {
          // 計画を削除・更新する場合
          for (let i = 0; i < this.deleteList.length; i++) {
            if (this.inventoryMethodSelected == "03" || this.inventoryMethodSelected == "05") {
              stockCheckPlan.push({
                locationSid: this.deleteList[i].locationSid, //ロケーションSID
                locationGroup: this.deleteList[i].locationGroup, //ロケーショングループ
                userSid: this.deleteList[i].person, //担当者SID
                deleteFlg: this.deleteList[i].deleteFlg, //削除フラグを1にセット
                stockCheckSid: this.deleteList[i].stockCheckSid, //棚卸SID
                updateDatetime: this.deleteList[i].updateDatetime, //更新日
                autoNo: this.deleteList[i].autoNo, // 連番
              });
            } else {
              stockCheckPlan.push({
                locationSid: this.deleteList[i].locationSid, //ロケーションSID
                locationGroup: this.deleteList[i].locationGroup, //ロケーショングループ
                userSid: this.deleteList[i].person, //担当者SID
                deleteFlg: this.deleteList[i].deleteFlg, //削除フラグを1にセット
                stockCheckSid: this.deleteList[i].stockCheckSid, //棚卸SID
                updateDatetime: this.deleteList[i].updateDatetime, //更新日
              });
            }
          }
        }
      } else {
        // 計画を登録する場合 荷動き品番以外
        if (!this.stockCheckTargetFlag) {
          for (var m = 0; m < this.inputList.length; m++) {
            stockCheckPlan.push({
              locationSid: this.inputList[m].locationSid, //ロケーションSID
              locationGroup: this.inputList[m].locationGroup, //ロケーショングループ
              userSid: this.inputList[m].person, //担当者SID
              deleteFlg: "0", //削除フラグを1にセット
              stockCheckSid: this.inputList[m].stockCheckSid ? this.inputList[m].stockCheckSid : "", //棚卸SID :
              updateDatetime: this.inputList[m].updateDatetime //TODO 不要かもしれない
                ? this.inputList[m].updateDatetime
                : "", //更新日
              itemStandardSid: this.inputList[m].itemStandardSid
                ? this.inputList[m].itemStandardSid
                : "", // 商品標準SId
            });
          }
        } else {
          // 計画を登録する場合 荷動き品番
          for (var n = 0; n < this.inputList.length; n++) {
            stockCheckPlan.push({
              locationSid: this.inputList[n].locationSid, //ロケーションSID
              locationGroup: this.inputList[n].locationGroup, //ロケーショングループ
              userSid: this.inputList[n].person, //担当者SID
              deleteFlg: "0", //削除フラグを1にセット
              stockCheckSid: this.inputList[n].stockCheckSid ? this.inputList[n].stockCheckSid : "", //棚卸SID :
              updateDatetime: this.inputList[n].updateDatetime //TODO 不要かもしれない
                ? this.inputList[n].updateDatetime
                : "", //更新日
              itemStandardSid: this.inputList[n].itemStandardSid
                ? this.inputList[n].itemStandardSid
                : "", // 商品標準SId
              itemCd: this.inputList[n].productCnCdShow ? this.inputList[n].productCnCdShow : "", //品番
              lotNo: this.inputList[n].lotShow ? this.inputList[n].lotShow : "", //ロット
            });
          }
        }
      }
      body.reqIdv.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.inventoryDate); //日時
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid"); //営業所SID
      body.reqIdv.clientSid = this.suppliersSelected; //取引先SID
      body.reqIdv.warehouseSid = this.$route.params.warehouseSid; //倉庫SID
      body.reqIdv.freeCostDiv = this.isPaid; //有償無償区分
      body.reqIdv.dispOnOffFlg = this.dispOnOffFlg; // 期待値
      body.reqIdv.stockCheckPlan = stockCheckPlan.slice();
      body.reqIdv.stockCheckNo = this.inventoryPlanNo; //棚卸指示No
      body.reqIdv.stockCheckDiv = this.inventoryMethodSelected; //棚卸方式
      body.reqIdv.registrationUpdateFlag = this.registrationUpdateFlag; //登録更新フラグ
      // console.debug("postApi() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.CNT_ADDPLANS, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.sixPageFlag = true;
              this.infoDialog.firstPageFlag = false;

              this.infoDialog.outsideClickNotCloseFlg = true;
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    targetItem() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_002;

      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 日付
      // body.reqIdv.yyyymmddHhmms = dateTimeHelper.convertUTC(this.inventoryDate);
      body.reqIdv.yyyymmddHhmms = this.searchDate;
      // ロケーションSid
      body.reqIdv.locationSidList = this.locationSidList; //ここをjson形式のデータに変える
      // 棚卸方式
      body.reqIdv.stockCheckDiv = this.inventoryMethodSelected;
      // console.debug("targetItem() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_STOCKCHECK_ITEM, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("targetItem() Response", response);

            const jsonData = JSON.parse(JSON.stringify(response.data));
            let list = [];

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 画面表示項目
              jsonData.resIdv.stockCheckTarget.forEach((row) => {
                let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  // ロケーショングループ
                  locationGroup: row.locationGroup,
                  // ロケーションNo
                  location: row.locationNo,
                  // ロケーションSid
                  locationSid: row.locationSid,
                  // 入荷No
                  inListNo: row.listNo,
                  // 入荷予定日
                  shipInsDate: row.scheduleDate,
                  // 品番
                  productCnCdShow: row.itemCd,
                  productCnCd: row.itemCd.substring(0, 50).trim(),
                  // 付帯品番
                  incidental: row.itemCd.slice(-50).trim(),
                  // 品名
                  productName: row.itemName == null ? "" : row.itemName,
                  // ロット
                  lotShow: row.lotNo,
                  // ロット、S/N
                  lot: lotNoObj.lot,
                  // 期限日、付属キー１、付属キー２
                  dueDate: lotNoObj.dueDate,
                  // 完了フラグ
                  completeFlg: row.completeFlg,

                  // 理論在庫
                  stock: row.stock,
                  // 商品標準
                  itemStandardSid: row.itemStandardSid,
                });
              });

              // 画面表示用リストに格納
              this.inputList = list;
              this.totalCount = this.inputList.length;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.countInstSearchFlag = true;
              this.infoDialog.countInstSearchPageParams = {
                // 遷移するときに渡された検索条件を返す
                suppliersSelected: this.suppliersSelected, //取引先
                method: this.inventoryMethodSelected, //棚卸方式
                warehouse: this.warehouseSelected, //倉庫
                dispOnOffFlg: this.dispOnOffFlg, // 期待値
              };

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 棚卸対象品番_GET
    getStockCheckTargetItem() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.locationSidList = this.locationSidList;
      // console.debug("getStockCheckTargetItem() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_STOCKCHECK_ITEM, config)
          .then((response) => {
            // console.debug("getStockCheckTargetItem() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.stockCheckTargetItemList.forEach((row) => {
                list.push({
                  locationSid: row.locationSid, //ロケーションSID
                  locationGroup: row.locationGroup,
                  location: row.locationNo,
                  lot: row.lotNo,
                  stock: row.stock,
                  productCnCd: row.itemCd,
                });
              });
              this.inputList = list;
              // 削除・更新用にdeleteListにおいておく
              var deleteList = list;
              for (var i = 0; i < deleteList.length; i++) {
                deleteList[i].deleteFlg = "0";
                this.deleteList.push(deleteList[i]);
              }
              // プルダウンにセットするためユーザー情報を取得する
              this.getUsers();
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    init() {
      this.inventoryDate = dateTimeHelper.convertJST();
      this.inventoryDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // 遷移前のページ名をdataに保存
      this.beforePage = this.$route.params.name;
      // 遷移前の検索条件をdataに保存
      this.person = this.$route.params.person;
      this.warehouse = this.$route.params.warehouse;
      this.location = this.$route.params.location;
      this.searchDate = this.$route.params.date;
      this.dispOnOffFlg = this.$route.params.expectedValue;
      // 検索条件
      if (this.$route.params.listSearch != null) {
        this.coutPlanListParameta = this.$route.params.listSearch;
      }
      // ロケーションSidの配列
      this.locationSidList = this.$route.params.locationSidList;

      if (this.beforePage == "CntInstList") {
        this.registrationUpdateFlag = "1";
        this.inventoryDate = dateTimeHelper.convertUTC2JST(
          this.$route.params.countImplementationDate
        );
        this.inventoryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.inventoryDate.substr(0, 10))
        );
        // 有償無償
        this.isPaid = this.$route.params.freeCostDiv;
        // 棚卸指示No編集不可
        this.noDisplayFlg = false;
        if (
          this.$route.params.stockMethodKbn == "03" ||
          this.$route.params.stockMethodKbn == "05"
        ) {
          // 荷動き品番の場合
          this.stockCheckTargetFlag = true;
          this.suppliersSelected = this.$route.params.clientSid;
          this.getCustomInfo();
          // 遷移前の検索条件を反映
          this.inventoryMethodSelected = this.$route.params.stockMethodKbn;
          this.inventoryMethodSelectedName = this.$route.params.stockMethod;
          this.inventoryPlanNo = this.$route.params.stockNo;
          // 棚卸計画明細を取得
          this.getPlanDetails();
        } else {
          // 前画面で選択した取引先Sidと一致する名前を表示させるため取引先マスタを呼び出す
          this.suppliersSelected = this.$route.params.clientSid;
          this.getCustomInfo();
          // 遷移前の検索条件を反映
          this.inventoryMethodSelected = this.$route.params.stockMethodKbn;
          this.inventoryMethodSelectedName = this.$route.params.stockMethod;
          this.inventoryPlanNo = this.$route.params.stockNo;
          // 棚卸計画明細を取得
          this.getPlanDetails();
        }
      }
      if (this.beforePage == "CntInstSearch") {
        this.registrationUpdateFlag = "0";
        this.inventoryDate = dateTimeHelper.convertUTC2JST(this.$route.params.date);
        this.inventoryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.inventoryDate.substr(0, 10))
        );
        // 棚卸指示No編集可
        this.noDisplayFlg = true;

        //  荷動き品番または循環棚卸品番有の場合
        if (this.$route.params.method == "03" || this.$route.params.method == "05") {
          this.stockCheckTargetFlag = true;
          this.inventoryMethodSelected = this.$route.params.method;
          this.warehouseSelected = this.$route.params.warehouse;
          this.suppliersSelected = this.$route.params.clientSid;
          this.getCustomInfo();
          this.getUsers();
          this.getMstCode();
          // this.getStockCheckTargetItem();
          this.officeSidForApi = this.$route.params.officeSid;
          this.warehouseSidForApi = this.$route.params.warehouseSid;

          // Getの処理
          this.targetItem();
        } else {
          // 遷移前の検索条件を反映
          this.inventoryMethodSelected = this.$route.params.method;
          this.warehouseSelected = this.$route.params.warehouse;
          this.suppliersSelected = this.$route.params.clientSid;
          this.getCustomInfo();
          // 前画面で選択したリストを表示
          this.inputList = this.$route.params.checkList;
          this.getUsers();
          this.getMstCode();
        }
      }
    },
    // 削除ボタン
    deleteItem(item) {
      // 指示SIDがある場合（既に登録済みのもの）
      if (this.inputList[0].stockCheckSid) {
        this.editedIndex = this.inputList.indexOf(item);
        var deleteIndex = this.deleteList.indexOf(item);

        // 削除Flgを１にしてポストする必要があるためdataとして持っておく
        this.inputList[this.editedIndex].deleteFlg = "1";
        this.deleteList[deleteIndex] = this.inputList[this.editedIndex];
        // 画面上からは明細削除
        this.inputList.splice(this.editedIndex, 1);
      } else {
        this.editedIndex = this.inputList.indexOf(item);
        this.inputList.splice(this.editedIndex, 1);
        // 全て削除した場合登録ボタンを非活性化
        if (this.inputList.length == 0) {
          this.noPlanFlg = true;
        }
      }
    },
    // 指示No採番
    setPlanNo() {
      // 取引先Cdと営業所Cdをセット
      this.clientCd = this.supplierList.officeCd;
      this.officeCd = sessionStorage.getItem("office_cd");
      // 棚卸日を編集
      var str = this.inventoryDate;
      // ハイフン・時間カット
      str = str.split("/").join("").slice(0, 8);
      // 指示Noにセット
      this.inventoryPlanNo = `${this.officeCd}-${this.clientCd}-${str}`;
    },
    /**prevDate
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // 今日以前の日付を選択できないようにする
    allowedDate(val) {
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return today <= new Date(val);
    },
    /**
     * 棚卸日を－１日します。
     */
    prevDate() {
      // 今日以前の日付を選択できないように制御
      let today = dateTimeHelper.convertJST();
      if (this.inventoryDateCal == dateTimeHelper.dateCalc(today)) {
        return;
      }
      let date = new Date(this.inventoryDateCal);
      this.inventoryDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 棚卸日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.inventoryDateCal);
      this.inventoryDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.inventoryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.inventoryDateCal = null;
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    inventoryDateCal(val) {
      this.inventoryDate = this.formatDate(val);
    },
    // 日付が変更されたら指示Noの末尾変更:営業所Cd-取引先Cd-日付
    inventoryDate(val) {
      // 既に登録されている場合修正不要
      if (this.beforePage == "CntInstList") {
        return;
      }
      // 棚卸日を編集
      var str = val;
      // ハイフン・時間カット
      str = str.split("/").join("").slice(0, 8);
      // 指示Noにセット
      this.inventoryPlanNo = `${this.officeCd}-${this.clientCd}-${str}`;
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}
</style>
